// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // its for QA...
  production: true,
  firebase: {
    apiKey: "AIzaSyCkWMRybY5O_xrt7UQHjUqsVdPAN-hPbng",
    authDomain: "qa-fuse-earth.firebaseapp.com",
    projectId: "qa-fuse-earth",
    storageBucket: "qa-fuse-earth.appspot.com",
    messagingSenderId: "999566892455",
    appId: "1:999566892455:web:fe48a21f56f37f27a52a82",
    measurementId: "G-76KEPGW6B1"
    // apiKey: 'AIzaSyCVnBsey3uLBUCWDQKQ8CdEMKjf0-p0LAc',
    // authDomain: 'geomocus-qa.firebaseapp.com',
    // databaseURL: 'https://geomocus-qa.firebaseio.com',
    // projectId: 'geomocus-qa',
    // storageBucket: 'geomocus-qa.appspot.com',
    // messagingSenderId: '202504574359',
    // appId: '1:202504574359:web:ea945d2af16635e63ea0d2',
    // measurementId: 'G-EFT26JZRYF'
  },
  disableConsoleLogs: false,
  sendGoogleAnalytics: true,
  feUserGuideTooltipAutoCloseDuration: 10000,
  // serverUrl: 'http://localhost:9090'
  serverUrl: 'https://qa.fuse.earth:8443/fusedotearth',
  serverUrlV2: 'https://qa.fuse.earth:8443/fusedotearth-v2'
  // serverUrl: 'https://192.168.29.55:9091',
  // serverUrlV2: 'https://192.168.29.55:9091'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
  // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
