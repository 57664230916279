import { AfterViewInit, Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthObservableService } from '../Services/authObservableService';
import { CommonService, ElementType } from '../Services/common.service';
import { GeobaseService } from '../Services/geobase.service';
import { BasemapService } from '../basemap/basemap.service';
import { TopicsService } from '../Services/topics.service';
import { SocialAuthService } from '../Services/socialAuthService';
import { CustomSpinner } from '../Services/SpinnerService';
import { jsPDF } from 'jspdf';
import { Observable, Subject } from 'rxjs';
import { GeoNotePadService } from '../Services/geo-notepad.service';
import { FeAdminDashboardService } from '../Services/feAdminDashborad.service';
import {formatDate } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// import { BnNgIdleService } from 'bn-ng-idle'; // import it to your component


@Component({
  selector: 'app-map-canvas',
  templateUrl: './map-canvas.component.html',
  styleUrls: ['./map-canvas.component.scss']
})
export class MapCanvasComponent implements OnInit, AfterViewInit {
  showAlert = false;
  alertMessage: string = ''; 
  alertType: string = 'warning';

  showCapture = false;
  show = false;
  closeNotepad = '';
  markGeopadLocation: boolean;
  showGeoSession: boolean;
  closeGeoSession: string;
  params: any = {};
  sessionId: Number = 0;
  uuid = '';
  isGuest = true;
  sessionDataCollected: boolean;
  currentSession: any = {};
  isDefault = true;
  globalObject = {
    geobase: {},
    pageType: '',
    sessionShare: {}
  };
  showOrHideUserLoginMenu = '';
  showCaptureExpandedView: string;
  showSessionExpandedView: string;
  viewMode: any = {};
  userInfo: any = {};
  // {
  //   type: /*'INDEPENDENT', /*/ 'ORG',
  //   orgName: 'xyz',
  //   orgId: '123434324'
  // };
  showGeodropdowninfo = false
  pageTypes = {
    SHARE: 'share',
    SESSION: 'session',
    COVID19: 'COVID19',
    DEFAULT: 'default'
  };
  mapProjectionUnits = '500 nm';
  scaleLineWidth = 0;
  showCovidInfo = false;
  showCovidInfoLegend = true;
  units = [
    { name: 'm'},
    { name: 'inches'},
    { name: 'feet'},
    { name: 'yard'},
    { name: 'miles'},
    
   
    { name: 'km'},
  ]
  infoNameAndColors: any[] = [
    { name: 'Oxygen', color: 'red' },
    { name: 'Blood', color: 'orange' },
    { name: 'Plasma', color: 'yellow' },
    { name: 'Hospital', color: 'green' },
    { name: 'Medicines', color: 'blue' },
    { name: 'Food', color: 'indigo' },
    { name: 'Vaccination', color: '#607d8b' },
    { name: 'Others', color: 'violet' }
  ];
  playCovidInfoVideo = false;
  loggedInUserProfile: any = {};
  currentPage = '';
  pageIcon = '';
  pageTitle = '';
  showFeSplashScreen = true;
  showUserLoginAlert = false;
  inputText = 'Please Login to view Session.';
  showAsModal = false;
  clickedOnMap = '';
  packageModulesData = package_features_control;
  triggerAnnotation = '';
  showDashboard = true;
  showAnalydropdowninfo=false;
  packageEventsSubject: Subject<any> = new Subject<any>();
  landingpageDiv = true
  userLoginDiv = false;
  userSignupDiv = false;
  sessionLoginForm: FormGroup;
  nonLoginSessionTime = 300000; //1800000  // 60000
  basemapType = 'bingstreet-Street (Bing)';

  constructor(
    private obsr: AuthObservableService, private actRoute: ActivatedRoute,
    private geobaseService: GeobaseService, private router: Router,
    private firebaseAuth: SocialAuthService, private spinner: CustomSpinner,
    private commonService: CommonService, private authObsr: AuthObservableService,
    private basemapService: BasemapService, private topicService: TopicsService, 
    private geoNotepadService: GeoNotePadService, 
    private feAdminService: FeAdminDashboardService) {
    this.params = this.actRoute.snapshot.params;
    console.log('params and url ', this.params, this.router.url);
    this.spinner.hide();
    this.geobaseProcess();
    this.authObsr.subscribeForAuthStatus('MapCanvasComponent', (authRes, msg) => {
      console.log('LOGIN STATUS CHANGED');
      console.log(authRes);
      console.log(msg);
      if (authRes.status === 'success') {
        this.isGuest = false;
        // this.getUserInfo();
        // this.getGeobase(0, true);
        this.showFeSplashScreen = false;
        this.geobaseProcess();
      } else if (authRes.status === 'failed') {
        this.isGuest = true;
        this.showGeoSession = false;
        if (String(this.router.url).includes('share')) {
          this.showUserLoginAlert = true;
          this.inputText = 'Please Login to view Session.';
        }
      }
    });
    this.sessionLoginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }
  @HostListener('window:keydown', ['$event'])
  /* escapeKeyPressed(event: KeyboardEvent) {
    console.log('tab clicked, prit pdf', event);
    this.downloadPDF();
  } */
  onKeyDown(event: KeyboardEvent) {
    // console.log('clicked on event ', event);
    /* if ((event.metaKey || event.ctrlKey) && event.key === 'q') {
      // console.log('clicked on event ctrl+q', event);
        this.downloadPDF();
    } */
  }

  geobaseProcess(): any {
    if (this.commonService.isValid(this.params.sessionId) && this.commonService.isValid(this.params.uuid)) {
      this.sessionId = Number(this.params.sessionId);
      this.sessionId = +(this.params.sessionId);
      this.uuid = this.params.uuid;
      console.log('Got session id &&  uuid', this.sessionId, this.uuid);
      // Here calling getGeobase of sessionId & uuid & default is false
      this.getSharedGeobase(this.sessionId, this.uuid);
      this.globalObject.pageType = this.pageTypes.SHARE; // 'share';
    } else if (this.commonService.isValid(this.params.sessionId) && String(this.router.url).includes('share')) {
      this.sessionId = Number(this.params.sessionId);
      this.sessionId = +(this.params.sessionId);
      this.isDefault = false;
      console.log('Got session id in sharing URL not UUid', this.sessionId, this.isDefault, this.isGuest);
      // Here calling the getGeobase of sessionId and default is false
      this.getSharedGeobase(this.sessionId, this.uuid);
      this.globalObject.pageType = this.pageTypes.SHARE; // 'session';
    } else if (this.commonService.isValid(this.params.sessionId)) {
      this.sessionId = this.params.sessionId;
      this.isDefault = false;
      console.log('Got session id', this.sessionId, this.isDefault);
      // Here calling the getGeobase of sessionId and default is false
      this.globalObject.pageType = this.pageTypes.SESSION; // 'session';
    } else if (String(this.router.url).includes('covid')){
      const geobaseInfo = {
        sessionId : 127,
        organizationId : 5,
        geopadId : 105,
        towerId : 178,
        owner : 44,
        isPublic : false,
        boundingBox : null,
        isDefault : true,
        name : 'Geobase_default',
        geobaseUuid : '55bb4d72-c591-42df-bdd2-2eae919a1632',
        status : 'ACTIVE',
        public : false,
        default : true,
        showAllSites: false,
      };
      this.currentSession = geobaseInfo;
      this.currentSession.status = 'completed';
      this.sessionDataCollected = true;
      this.globalObject.geobase = geobaseInfo;

      this.isDefault = false;
      this.sessionId = 127;
      console.log('COVID19 special page...', this.isDefault, this.sessionId);
      console.log(this);
      this.globalObject.pageType = this.pageTypes.COVID19; // 'covid19';
      console.log(this.globalObject);
      console.log(JSON.stringify(this.globalObject));
    } else {
      this.isDefault = true;
      this.sessionId = 0;
      console.log('No session id & uuid', this.sessionId, this.isDefault);
      this.globalObject.pageType = this.pageTypes.DEFAULT; // 'default';
    }
    if (this.commonService.isValid(localStorage.getItem('token'))) {
      // Here calling the getGeobase of default is true
      this.isGuest = false;
      this.getUserInfo();
      this.getProfileData();
      this.showFeSplashScreen = false;
      // this.getDefaultGeobase();
      if (!this.commonService.isValid(this.params.uuid) && !(String(this.router.url).includes('share')) &&
          this.globalObject.pageType !== this.pageTypes.COVID19) {
        this.getGeobase(this.sessionId, this.isDefault);
      }
    } else {
      // Here no user login..
      this.isGuest = true;
    }
  }
  getSubscriptionData(orgId, email, orgName): void {
    console.log('IN getSubscriptionData');
    this.firebaseAuth.getSubscriptionData(orgId, email).subscribe(result => {
      console.log('GOT Subscription List', "rajinikanth");
      console.log(result);
      if(result.orgPackageRela !== null) {
        if(result.orgPackageRela.status == "InActive") {
          console.log('not activated the by FE Admin')
          return;
        }
      }
      package_features_control["default"]["package"] = { "name": result.packageInfo.name, "features" : [] }; 
      console.log(package_features_control)
      const packageLevelForBNB = result.packageInfo.name + '('+orgName+')';
      this.authObsr.updateOrgName(packageLevelForBNB);
      result.packageInfo.packageItems.forEach(element => {
        const itemData = {
          "name": element.name,
          "isAccess": true
        }
        package_features_control["default"]["package"]["features"].push(itemData)        
      });
      this.packageModulesData = package_features_control;
      localStorage.setItem('packageItems', JSON.stringify(package_features_control["default"]["package"]["features"]));
    }, error => {
      console.log('ERROR WHILE Subscription DATA');
      console.log(error);
      if(error.status === 500) {
        // Here need to update the package to this user as Enagage for 30days
        const startDate = new Date();
        let endDate = new Date();
        const startDateValue = formatDate(startDate, 'yyyy-MM-dd', 'en-US');
        endDate.setDate(startDate.getDate() + 30 );
        const endDateValue = formatDate(endDate, 'yyyy-MM-dd', 'en-US');
        console.log(startDateValue, ' === ', endDateValue, " == ", startDate, " === ", endDate)
        const requestJson = { orgId: orgId, subscriptionId: 3, startDate: startDateValue, endDate: endDateValue,
            emailId:email,
            status:"Active"
        }
        this.feAdminService.updateOrgSubscriptionDetails(requestJson).subscribe( response => {
          console.log('updating the org subscriptions response is ', response);          
          this.getSubscriptionData(orgId, email, orgName);    
          const requestJsonForEmail = { emailSubject: 'Welcome to fuse.earth!', emailType: 'ToUserForGmailSignupEmail', userName:email.split('@')[0], startDate: startDateValue, endDate: endDateValue,
            toEmail:email,
        }
          this.feAdminService.sendEmailForGmailSignupUsers(requestJsonForEmail).subscribe( responseEmail => {
            console.log('sending email to register users ', responseEmail);     
          });
        });
      }
    });
  }

  getProfileData(): void{
    console.log('IN getProfileData');
    this.firebaseAuth.getProfileData()
          .subscribe(result => {
            console.log('GOT PROFILE');
            console.log(result);
            this.loggedInUserProfile = result;
            if(result.user.email === 'fuse.earth@mapsolgeo.com') {
              window.open('https://fuse.earth/fe/dashboard', '_self');
            }
          }, error => {
            console.log('ERROR WHILE GETTING PROFILE DATA');
            console.log(error);
            if(error.status === 403 && error.statusText === 'Forbidden') {
              // window.alert('Session was Expired. please Logout & Login again and Continue to Fuse Earth')
              this.alertMessage = "Session was Expired. please Logout & Login again and Continue to Fuse Earth ";
              this.alertType = 'success';  // Set the type of alert
              this.showAlert = true;
              // this.firebaseAuth.logoutFromFirebase();
              // localStorage.clear();
              // window.location.reload();
              this.showFeSplashScreen = true;
              this.landingpageDiv = true;
            }
            if(error.status === 403) {
              // window.alert('Session was Expired. please Logout & Login again and Continue to Fuse Earth')
              this.alertMessage = "Session was Expired. please Logout & Login again and Continue to Fuse Earth ";
              this.alertType = 'success';  // Set the type of alert
              this.showAlert = true;
              this.showFeSplashScreen = true;
              this.landingpageDiv = true;
            }
          });
  }

  getUserInfo(): void{
    this.topicService.getUserInfo()
          .subscribe(res => {
            console.log('GOT USER INFO');
            console.log(res);
            this.storeUserInfo(res);
          }, error => {
            console.log('ERROR WHILE GETTING USER INFO');
            console.log(error);
            if(error.status === 403 && error.statusText === 'Forbidden') {
              // window.alert('please try to singnout & signin again, user session expired')
              this.alertMessage = "please try to singnout & signin again, user session expired ";
              this.alertType = 'success';  // Set the type of alert
              this.showAlert = true;
            }
          });
  }

  storeUserInfo(res): void{
    let type = '';
    let orgName = ';'
    let packageItems = ''
    if (res.name === 'FuseEarth') {
      type = 'INDEPENDENT';
      orgName = 'Individual';
      this.obsr.updateOrgName('Individual');
    } else {
      type = 'ORG';
      orgName = res.name;
      this.obsr.updateOrgName(res.name);
    }
    if (this.globalObject.pageType !== this.pageTypes.COVID19) {
      this.userInfo = {
        type, // 'INDEPENDENT', // 'ORG',
        orgName: res.name,
        orgId: res.organizationId, // '123434324'
        packageItems,
      };
    } else {
      this.userInfo = {
        type: 'ORG',
        orgName: 'Geobase_default',
        orgId: 5 // '123434324'
      };
    }    
    this.getSubscriptionData(res.organizationId, localStorage.getItem('email'), orgName);
    console.log('what is the package items? ', package_features_control);
  }
  ngOnInit(): any {
    setTimeout(() => {
      if (this.globalObject.pageType !== this.pageTypes.COVID19) {
        this.show = true;
      }
    }, 1000);
    localStorage.removeItem('projCode')
    localStorage.removeItem('refresh')
    localStorage.removeItem('all sites')

    this.feAdminService.getGlobalSettingsList().subscribe(response => {
      console.log(response)
      response.forEach(result => {
        if(result.name.toUpperCase() === 'NonLoginTime'.toUpperCase()) {
          this.nonLoginSessionTime = result.value * 60000;
        }
        if(result.name.toUpperCase() === 'BaseMapType'.toUpperCase()) {
          this.basemapType = result.value;
          const mapName = this.basemapType.split('-')[1];
          const mapType = this.basemapType.split('-')[0];
          this.filterMapTypes(mapType, mapName);
        }
      });
    }, error => {
      console.log('error is ', error);
    });
    
  }

  ngAfterViewInit(): void{
    if (this.globalObject.pageType === this.pageTypes.COVID19) {
      setTimeout(() => {
        this.showGeopadWindowFun({data: 'geopad', event: {ctrlKey: false} });
        this.show = true;
        // this.basemapService.getCurrentBasemap().getLayers().forEach(layer => {
        //   if (layer.values_.name === 'satellite' || layer.values_.name === 'terrain'
        //   || layer.values_.name === 'toner' || layer.values_.name === 'bingsatellite') {
        //     layer.setVisible(false);
        //   } else if (layer.values_.name === 'openstreet') {
        //     layer.setVisible(true);
        //   }
        // });
        // this.basemapService.getCurrentBasemap().getView().setCenter([78.9629, 20.5937]);
        // this.basemapService.getCurrentBasemap().getView().setZoom(7);
        this.basemapService.getCurrentBasemap().on('moveend', (e) => {
          // console.log(e);
          this.getMapProjectionUnits();
          this.getScaleLineWidth();
          // this.basemapService.setLoadScaleLine();
        });
        // setTimeout(() => {
        //   this.basemapService.userLocation();
        //   this.basemapService.getCurrentBasemap().getView().setZoom(17);
        // }, 10000);
      }, 1000);
    }
  }
  toggleAwareness(event): void{
    this.viewMode = event;
  }
  showGeopadWindowFun(event): any {
    this.viewMode = {
      mode: 'capture',
      show: true,
      timestamp: new Date().getTime(),
      from: 'geopad',
      op: ''
    };
    if (event.data === 'geopad') {
      if (this.showCapture) {
        this.showCaptureExpandedView = String(new Date().getTime());
      }
      this.showCapture = true;
    }
    if (event.event.ctrlKey && event.data === 'geopad') {
      this.markGeopadLocation = true;
    } else {
      this.markGeopadLocation = false;
    }
    if (event.data === 'annotate') {
      // this.viewMode.from = event.data;
      // this.currentSession.showAllSites = false;
      // this.showCapture = true;
      this.triggerAnnotation = new Date().getTime().toString();
    }
  }
  saveAnnotationFun(event): void{
    this.viewMode = {
      mode: 'capture',
      show: true,
      timestamp: new Date().getTime(),
      from: 'annotate',
      op: '',
      coords: event.coords,
      features: event.features
    };
    this.currentSession.showAllSites = false;
    this.showCapture = true;
  }
  showGeoSessionWindowFun(event): any {
    if (event.data === 'geosession') {
      if (this.showGeoSession) {
        this.showSessionExpandedView = String(new Date().getTime());
      }
      this.showGeoSession = true;
    }
  }
  closeCaptureWindow(): any {
    this.showCapture = false;
    this.markGeopadLocation = false;
    this.closeNotepad = String(new Date().getTime());
    this.viewMode = {
      mode: '',
      show: false,
      timestamp: new Date().getTime(),
      from: 'geopad',
      op: ''
    };
    this.currentSession.showAllSites = false;
  }
  loadSessionFun(event): void{
    console.log('Load Session');
    console.log(event);
    this.globalObject.geobase = event;
    this.currentSession = event;
    this.currentSession.status = 'completed';
  }
  closeGeoSessionWindow(): any {
    this.showGeoSession = false;
    this.closeGeoSession = String(new Date().getTime());
  }

  validtingShareByMeAndShareWithMeSessionsList(sessionId, isValidUser, filterName, isFinalCall): any {
    this.geobaseService.getSharedGeobaseNew(filterName, sessionId)
    .subscribe(geobaseList => {
      console.log('Got geobaseList info in filter', geobaseList, localStorage, localStorage.getItem('email'));
      console.log(this);
      if (this.commonService.isValid(geobaseList)) {
        console.log('geobaseList present');
        if (geobaseList.body.length > 0) {
          geobaseList.body.forEach(geobase => {
            console.log(geobase);
            console.log(geobase.session.sessionId, sessionId, typeof(sessionId), typeof(geobase.session.sessionId));
            geobase.session.sessionId = Number(geobase.session.sessionId);
            geobase.session.sessionId = +(geobase.session.sessionId);
            geobase.session.showAllSites = false;
            if (sessionId === geobase.session.sessionId){
              if (geobase.sessionShare !== null) {
                if ((localStorage.getItem('email') === geobase.sessionShare.recipientUserEmail) ||
                (localStorage.getItem('email') === geobase.sessionShare.senderUserEmail)) {
                  isValidUser = true;
                  geobase.session.sessionId = Number(geobase.session.sessionId);
                  geobase.session.sessionId = +(geobase.session.sessionId);
                  this.currentSession = geobase.session;
                  this.currentSession.status = 'completed';
                  this.sessionDataCollected = true;
                  // this.globalObject.geobase = geobase.session;
                  this.globalObject.geobase = this.commonService.isValid(geobase.session) ? geobase.session : {};
                  this.globalObject.sessionShare = this.commonService.isValid(geobase.sessionShare) ? geobase.sessionShare : {};
                  console.log('global Object is ', this.globalObject, this.currentSession);
                  if (geobase.session.boundingBox !== null) {
                    console.log('Bounding box is ', geobase.session.boundingBox);
                    this.basemapService.getCurrentBasemap().getView().fit(geobase.session.boundingBox.map(Number));
                  }
                  return true;
                }
              }
            }
          });
        }
      }
      if (!isValidUser) {
        if (!isFinalCall) {
          this.validtingShareByMeAndShareWithMeSessionsList(sessionId, false, 'shareByMe', true);
        } else {
          this.showUserLoginAlert = true;
          this.inputText = 'You currently dont have the permission to view this session.';
        }
      // this.showUserLoginAlert = true;
      // this.inputText = 'You currently dont have the permission to view this session.';
      // window.alert('You currently dont have the permission to view this session');
      // window.open('https://qa.fuse.earth/', "_self");
    }
      return false;
    }, error => {
      console.log('Error while getting shared geobaseinfo');
      console.log(error);
      if (error.errorCode === 500) {
      }
      if (error.status === 403 && error.statusText === 'Forbidden') {
        if (String(this.router.url).includes('share')) {
          this.showUserLoginAlert = true;
          this.inputText = 'Please Login to view Session.';
        }
      }
      this.currentSession.status = 'failed';
      this.sessionDataCollected = true;
    });
    return false;
  }

  getSharedGeobase(sessionId, uuid): any {
    // its new API
    const isValidUser = false;
    this.validtingShareByMeAndShareWithMeSessionsList(sessionId, isValidUser, 'shareWithMe', false);
    /* const shareByMeStatus = this.validtingShareByMeAndShareWithMeSessionsList(sessionId, isValidUser, 'shareWithMe');
    const shareWithMeStatus = this.validtingShareByMeAndShareWithMeSessionsList(sessionId, isValidUser, 'shareByMe');
    console.log(shareByMeStatus, shareWithMeStatus);
    if(shareByMeStatus || shareWithMeStatus) {
      isValidUser = true;
    } else {
      this.showUserLoginAlert = true;
      this.inputText = 'You currently dont have the permission to view this session.';
    } */
  }

  getGeobase(sessionId, isDefault): void {
    console.log('getting the geobase in mapCanvas', sessionId, isDefault);
    this.sessionDataCollected = false;
    this.currentSession.status = 'loading';
    this.geobaseService.getGeobase(sessionId, isDefault)
      .subscribe(geobaseInfo => {
        console.log('Got geobaseInfo info in mapCanvas');
        console.log(geobaseInfo);
        if (!this.commonService.isValid(geobaseInfo)) {
          console.log('No geobase info present in mapCanvas');
          this.geobaseService.createGeobaseTowerGeopad()
            .subscribe(resultSession => {
              const result = resultSession.body;
              console.log('result of saving the new geobase with tower & geopad', result);
              if (!this.commonService.isValid(result)) {
                console.log('New session not created');
              } else {
                console.log('New session created');
                result.showAllSites = false;
                this.currentSession = result;
                this.globalObject.geobase = {};
                this.sessionDataCollected = true;
              }
              this.currentSession.status = 'completed';
            }, error => {
              console.log('Error while creating new geobase session');
              console.log(error);
              if (error.errorCode === 500) {
              }
              this.currentSession.status = 'completed';
              this.sessionDataCollected = true;
            });
        } else {
          geobaseInfo.showAllSites = false;
          this.currentSession = geobaseInfo;
          this.currentSession.status = 'completed';
          this.sessionDataCollected = true;
          this.globalObject.geobase = this.commonService.isValid(geobaseInfo) ? geobaseInfo : {};
          if (geobaseInfo.boundingBox !== null) {
            console.log('Bounding box is ', geobaseInfo.boundingBox);
            this.basemapService.getCurrentBasemap().getView().fit(geobaseInfo.boundingBox.map(Number));
          }
        }
      }, error => {
        console.log('Error while getting workspace');
        console.log(error);
        if (error.errorCode === 500) {
        }
        this.currentSession.status = 'failed';
        this.sessionDataCollected = true;
      });
  }

  pageClickEventfun(event): any {
    this.showOrHideUserLoginMenu = event;
  }

  pageClickEventOnMap(event): any {
    this.clickedOnMap = event;
    this.showOrHideUserLoginMenu = event;
  }


  /***********
   *
   * FOR COVID PAGE
   *
   */
  getMapProjectionUnits(): any {
    this.basemapService.getCurrentBasemap().controls.forEach(control => {
      if (this.commonService.isValid(control.values_)) {
        if (control.values_.units !== undefined) {
          setTimeout(() => {
            // console.log('Here scal line ', control, control.renderedHTML_, control.element.innerText);
            this.mapProjectionUnits = this.setMapProjectionUnits(control.element.innerText);
          }, 1000);
        }
      }
    });
  }
  setMapProjectionUnits(val): any {
    const tempArr = val.split(' ');
    if (tempArr.length > 1) {
      // Here adding new code for view factory related
      const scaleLine = tempArr[3].match(/\d+/g);
      const scalByHalf = Number(scaleLine) / 2;
      const value = tempArr[2].slice(0, tempArr[2].length - (scalByHalf.toString().length)).slice(0, -1); // .replace(/\,/g, '');
      // console.log(tempArr, ' :: ', scaleLine, ' : ', value);
      const viewFactory = tempArr[0] + tempArr[1] + value;
      this.authObsr.updateViewFactory(viewFactory);
      if (scaleLine.includes('.')) {
        let fixedNum = scaleLine.substr(scaleLine.indexOf('.') + 1).length;
        // console.log(fixedNum);
        if (fixedNum > 5) {
          // console.log('MORE THAN 5. SETTING TO 5');
          fixedNum = 5;
        }
        return String(Number(scaleLine).toFixed(fixedNum)) + ' ' + tempArr[4];
      } else {
        return scaleLine + ' ' + tempArr[4];
      }
    } else {
      return val;
    }
  }
  getScaleLineWidth(): any {
    setTimeout(() => {
      try {
        const mapControlCollection: any[] = this.basemapService.getCurrentBasemap().getControls().array_;
        // console.log(mapControlCollection);
        mapControlCollection.forEach(element => {
          // console.log(element);
          // console.log(element.renderedWidth_);
          if (this.commonService.isValid(element.renderedWidth_)) {
            this.scaleLineWidth = element.renderedWidth_;
          }
        });
        console.log('SCALE LINE WIDTH : ', this.scaleLineWidth);
      } catch (e) {
        console.log(e);
      }
    }, 1000);
  }

  zoomIn(): any {
    // console.log(this.basemapService.getCurrentBasemap().getView());
    // console.log(this.basemapService.getCurrentBasemap().getView().getZoom());
    const currentZoom = this.basemapService.getCurrentBasemap().getView().getZoom();
    const maxZoom = this.basemapService.getCurrentBasemap().getView().getMaxZoom();
    this.getScaleLineWidth();
    if (currentZoom < maxZoom) {
      this.basemapService.getCurrentBasemap().getView().setZoom(this.basemapService.getCurrentBasemap().getView().getZoom() + 1);
      this.getMapProjectionUnits();
    }
  }
  zoomOut(): any {
    const currentZoom = this.basemapService.getCurrentBasemap().getView().getZoom();
    const minZoom = this.basemapService.getCurrentBasemap().getView().getMinZoom();
    this.getScaleLineWidth();
    if (currentZoom > minZoom) {
      this.basemapService.getCurrentBasemap().getView().setZoom(this.basemapService.getCurrentBasemap().getView().getZoom() - 1);
      this.getMapProjectionUnits();
    }
  }

  getYoutubeEmbedUrl(url, autoplay = false): any {
    let retUrl = '';
    if (url.includes('embed')) {
      retUrl = url.trim();
    } else {
      retUrl = 'https://www.youtube.com/embed/' + this.getYoutubeVideoId(url);
      if (autoplay) {
        retUrl = retUrl + '?autoplay=1';
      }
    }
    return retUrl;
  }
  getYoutubeVideoId(url): any {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }

  openPageFun(page): void{
    console.log('openPageFun');
    console.log(page);
    if (page === 'profile'){
      this.pageIcon = 'assets/images/profile.png';
      this.pageTitle = 'Profile';
    } else if (page === 'user-mgmt'){
      this.pageIcon = 'assets/images/profile.png';
      this.pageTitle = 'User Management';
    } else if (page === 'proj-mgmt'){
      this.pageIcon = 'assets/images/profile.png';
      this.pageTitle = 'Project Management';
    } else if (page === 'team-mgmt'){
      this.pageIcon = 'assets/images/profile.png';
      this.pageTitle = 'Team Management';
    } else if (page === 'subscription-billing'){
      this.pageIcon = 'assets/images/profile.png';
      this.pageTitle = 'Subscription Billing';
    }
    this.currentPage = page;
  }
  closePage(): void{
    this.currentPage = '';
    this.pageIcon = '';
    this.pageTitle = '';
  }

  openTourLink(from): void{
    let url = '';
    let withinSameTab = false;
    if (from === 'createSite'){
      url = 'https://mapsolgeo.com/workflow-1-locate-sites-and-create-new-site-information/';
    }
    else if (from === 'playstore'){
      url = 'https://play.google.com/store/apps/details?id=com.mapsolgeo.fuseearth.v1&pli=1';
    }
    else if (from === 'storeProject'){
      url = 'https://mapsolgeo.com/workflow-2-store-project-information-in-a-secure-central-cloud-space/';
    }
    else if (from === 'editManage'){
      url = 'https://mapsolgeo.com/workflow-3-edit-and-manage-project-information/';
    }
    else if (from === 'addGeodata'){
      url = 'https://mapsolgeo.com/workflow-4-add-geo-data-and-analyze-your-sites/';
    }
    else if (from === 'collaborate'){
      url = 'https://mapsolgeo.com/workflow-5-collaborate-and-present-your-work/';
    }
    else if (from === 'read'){
      // url = 'https://mapsolgeo.com/fuseearth/blog/';
      url = 'https://mapsolgeo.com/blogs/';
      withinSameTab = true;
    } else if (from === 'watch'){
      // url = 'https://mapsolgeo.com/fuseearth/watch.html';
      url = 'https://mapsolgeo.com/videos/';
      withinSameTab = true;
    } else if (from === 'learn'){
      // url = 'https://mapsolgeo.com/fuseearth/learn.html';
      url = 'https://mapsolgeo.com/tutorials/';
      withinSameTab = true;
    } else if (from === 'tour'){
      // url = 'https://mapsolgeo.com/fuseearth/tour.html';
      url = 'https://mapsolgeo.com/tour/';
      withinSameTab = true;
    } else if (from === 'support'){
      // url = 'https://mapsolgeo.com/fuseearth/support.html';
      url = 'https://mapsolgeo.com/contact-us/';
      withinSameTab = true;
    }else if (from === 'fb'){
      url = 'https://facebook.com/fuse.earth';
    } else if (from === 'linkedin'){
      url = 'https://www.linkedin.com/showcase/fuse-earth/about/';
    } else if (from === 'twitter'){
      url = 'https://twitter.com/fuse_earth';
    } else if (from === 'slack'){
      url = 'https://mapsolworkspace.slack.com';
    } else if (from === 'instagram'){
      // url = 'https://www.instagram.com/mapsol_geo_solutions/?igshid=YmMyMTA2M2Y%3D';
      url = 'https://instagram.com/fuse_earth?igshid=NTc4MTIwNjQ2YQ==';
    }
    
    window.open(url, '_blank');
    // if (withinSameTab){
    //   window.open(url, '_self');
    // }
    // else{
    //   window.open(url, '_blank');
    // }

  }

  defaultFEURL(): any {
    window.open('https://fuse.earth/', '_self');
  }

  showAsModalFun(event): void{
    // this.showAsModal = true;
    // this.showFeSplashScreen = true;
    this.userLoginDiv=false;
    this.landingpageDiv=true;
    this.userSignupDiv=false;
  }
  loadStoreNotesObject(e){
     console.log(e,"i am noted saved in mapcanvas")
     this.geoNotepadService.storeSavedNotesObject(e)
  }
  feDashboardClick(event): any {
    console.log('what is event ', event);
  }
  showGeoDropdownEvent(e){
    console.log(e,"check e value in mapcanvas")
    if(e === 'true'){
     this.showGeodropdowninfo = true
    }else{
      this.showGeodropdowninfo = false
    }
    console.log(this.showGeodropdowninfo, "check dropdown value in map-canvas")
  }
  checkGeoDropdownClickFunc(x){
    console.log(x, "check selected click")
    this.showGeodropdowninfo = false
    this.showAnalydropdowninfo = false
    if(x === 'inches'){
      console.log("check in geotray dropdown")
      localStorage.setItem('unit', `in`);
    }else if(x === 'yard'){
      localStorage.setItem('unit', 'yard');
    }else if(x === 'cm'){
      localStorage.setItem('unit', 'cm');
    }else if(x === 'm'){
      localStorage.setItem('unit', 'm');
    }else if(x === 'feet'){
      localStorage.setItem('unit', 'ft');
    }else if(x === 'km'){
      localStorage.setItem('unit', 'km');
    }else{
      localStorage.setItem('unit', 'mile');
    }
  }

  getErrorMsg(ctrl: FormControl, name: string, type = ElementType.INPUT): string {
    return this.commonService.getFormErrorMsg(ctrl, name, type);
  }
  
  checkAnalyDropdownClickFunc(x){
    console.log(x, "check selected analysis click")
    this.showAnalydropdowninfo = false
    this.showGeodropdowninfo = false
    if(x === 'inches' || x === 'yard' || x === 'cm' || x === 'm'){
      localStorage.setItem('unit', `${x}`);
    }else if(x === 'feet'){
      localStorage.setItem('unit', 'ft');
    }else if(x === 'km'){
      localStorage.setItem('unit', 'metric');
    }else{
      localStorage.setItem('unit', 'us');
    }
  }
  userSessionNonLogin(emailId) {
    console.log('its user Session Landing page API calls ', emailId);
    // this.feAdminService.getUserEmailsList().subscribe(emailsList => {
    //   console.log('get emails List ', emailsList);
    // });
    // this.feAdminService.getUserEmailInfo(emailId).subscribe(emailInfo => {
    //   console.log('get email info ', emailInfo);
    // });
    this.firebaseAuth.logoutFromFirebase();
    localStorage.clear();
    this.feAdminService.saveUserEmailInfoNonLogin(emailId).subscribe(emailInfo => {
      console.log('save email info ', emailInfo);
      if(emailInfo.body !== null || emailInfo.body !== undefined) {
        if(emailInfo.body.isFeUser) {
          // window.alert("This Email " + emailId + " already registered. Please Login !!")
          this.alertMessage = "This Email " + emailId + " already registered. Please Login !! ";
          this.alertType = 'success';  // Set the type of alert
          this.showAlert = true;
          return;
        } else if (emailInfo.body.status === 'exist') {
          // window.alert("This Email " + emailId + " already free session done. Please Signup !!")
          this.alertMessage = "This Email " + emailId + " already free session done. Please Signup !! ";
          this.alertType = 'success';  // Set the type of alert
          this.showAlert = true;
          return;
        } else {
          this.landingpageDiv=false; 
          this.userLoginDiv=false;
          this.showFeSplashScreen=false;
          setTimeout(() => {
            console.log('session expired');
            // window.alert('Hello User, your free user session time out. Please signup !!!');
            this.alertMessage = "Hello User, your free user session time out. Please signup !!! ";
            this.alertType = 'success';  // Set the type of alert
            this.showAlert = true;
            this.landingpageDiv=true;
            this.landingpageDiv=false;
            this.showFeSplashScreen=true;
            this.userSignupDiv=true;
          }, this.nonLoginSessionTime); //1800000  // 60000
          // this.bnIdle.startWatching(120).subscribe((isTimedOut: boolean) => {
          //   if (isTimedOut) {
          //     console.log('session expired');
          //     window.alert('Hello User, your free user session time out. Please signup !!!');
          //     this.landingpageDiv=true;
          //     this.showFeSplashScreen=true;
          //   }
          // });
        }
      }
    }, error => {
      this.alertMessage = "Server Error ";
      this.alertType = 'success';  // Set the type of alert
      this.showAlert = true;
    });
  }
  closeAlert() {
    this.showAlert = false;
    setTimeout(() => {
      this.alertMessage = '';  // Clear the message after the transition ends
    }, 500); // Wait for the transition to finish before clearing the message
  }

  filterMapTypes(mapType, mapRealname): any {
    // const mapType = mapTypeObj.value;
    // console.log('what is map type: ', mapType, this.isBaseMapOptActive);
    // this.mapTypeName = mapType;
    // this.selecteMapType = mapTypeObj;
    this.authObsr.updateBaseLayerName(mapRealname);
    // this.isBaseMapOptActive = !this.isBaseMapOptActive;
    // #TODO - later this code need to be simply & easly...
    this.basemapService.getCurrentBasemap().getLayers().forEach(layer => {
      console.log('layer name ', mapType, layer.values_.name, layer.getVisible(), layer);
      if (mapType === 'openstreet') {
        if (layer.values_.name === 'satellite' || layer.values_.name === 'terrain'
          || layer.values_.name === 'toner' || layer.values_.name === 'bingsatellite'
          || layer.values_.name === 'bingstreet' || layer.values_.name === 'googlestreet' 
          || layer.values_.name === 'googlesatellite' || layer.values_.name === 'USGSTopo'
          || layer.values_.name === 'USTopo') {
          layer.setVisible(false);
        } else if (layer.values_.name === 'openstreet') {
          layer.setVisible(true);
        }
      }
      if (mapType === 'satellite') {
        if (layer.values_.name === 'openstreet' || layer.values_.name === 'terrain'
          || layer.values_.name === 'toner' || layer.values_.name === 'bingsatellite'
          || layer.values_.name === 'bingstreet' || layer.values_.name === 'googlestreet' 
          || layer.values_.name === 'googlesatellite'  || layer.values_.name === 'USGSTopo'
          || layer.values_.name === 'USTopo') {
          layer.setVisible(false);
        } else if (layer.values_.name === 'satellite') {
          layer.setVisible(true);
        }
      }
      if (mapType === 'terrain') {
        if (layer.values_.name === 'satellite' || layer.values_.name === 'openstreet'
          || layer.values_.name === 'toner' || layer.values_.name === 'bingsatellite'
          || layer.values_.name === 'bingstreet' || layer.values_.name === 'googlestreet' 
          || layer.values_.name === 'googlesatellite'  || layer.values_.name === 'USGSTopo'
          || layer.values_.name === 'USTopo') {
          layer.setVisible(false);
        } else if (layer.values_.name === 'terrain') {
          layer.setVisible(true);
        }
      }

      if (mapType === 'toner') {
        if (layer.values_.name === 'satellite' || layer.values_.name === 'openstreet'
          || layer.values_.name === 'terrain' || layer.values_.name === 'bingsatellite'
          || layer.values_.name === 'bingstreet' || layer.values_.name === 'googlestreet' 
          || layer.values_.name === 'googlesatellite'  || layer.values_.name === 'USGSTopo'
          || layer.values_.name === 'USTopo') {
          layer.setVisible(false);
        } else if (layer.values_.name === 'toner') {
          layer.setVisible(true);
        }
      }

      if (mapType === 'bingsatellite') {
        if (layer.values_.name === 'satellite' || layer.values_.name === 'openstreet'
          || layer.values_.name === 'terrain' || layer.values_.name === 'toner'
          || layer.values_.name === 'bingstreet' || layer.values_.name === 'googlestreet' 
          || layer.values_.name === 'googlesatellite'  || layer.values_.name === 'USGSTopo'
          || layer.values_.name === 'USTopo') {
          layer.setVisible(false);
        } else if (layer.values_.name === 'bingsatellite') {
          layer.setVisible(true);
        }
      }
      // New code for street view of bing
      if (mapType === 'bingstreet') {
        if (layer.values_.name === 'openstreet' || layer.values_.name === 'terrain'
          || layer.values_.name === 'toner' || layer.values_.name === 'bingsatellite'
          || layer.values_.name === 'googlestreet' || layer.values_.name === 'satellite' 
          || layer.values_.name === 'googlesatellite'  || layer.values_.name === 'USGSTopo'
          || layer.values_.name === 'USTopo') {
          layer.setVisible(false);
        } else if (layer.values_.name === 'bingstreet') {
          layer.setVisible(true);
        }
      }

      // New code for street view of bing
      if (mapType === 'googlestreet') {
        if (layer.values_.name === 'openstreet' || layer.values_.name === 'terrain'
          || layer.values_.name === 'toner' || layer.values_.name === 'bingsatellite'
          || layer.values_.name === 'bingstreet' || layer.values_.name === 'satellite' 
          || layer.values_.name === 'googlesatellite'  || layer.values_.name === 'USGSTopo'
          || layer.values_.name === 'USTopo') {
          layer.setVisible(false);
        } else if (layer.values_.name === 'googlestreet') {
          layer.setVisible(true);
        }
      }

      // New code for satellite view of google
      if (mapType === 'googlesatellite') {
        if (layer.values_.name === 'openstreet' || layer.values_.name === 'terrain'
          || layer.values_.name === 'toner' || layer.values_.name === 'bingsatellite'
          || layer.values_.name === 'bingstreet' || layer.values_.name === 'satellite' 
          || layer.values_.name === 'googlestreet'  || layer.values_.name === 'USGSTopo'
          || layer.values_.name === 'USTopo') {
          layer.setVisible(false);
        } else if (layer.values_.name === 'googlesatellite') {
          layer.setVisible(true);
        }
      }
      // New code for usgstopo
      if (mapType === 'USGSTopo') {
        if (layer.values_.name === 'openstreet' || layer.values_.name === 'terrain'
          || layer.values_.name === 'toner' || layer.values_.name === 'bingsatellite'
          || layer.values_.name === 'bingstreet' || layer.values_.name === 'satellite' 
          || layer.values_.name === 'googlestreet'  || layer.values_.name === 'googlesatellite'
          || layer.values_.name === 'USTopo') {
          layer.setVisible(false);
        } else if (layer.values_.name === 'USGSTopo') {
          layer.setVisible(true);
        }
      }
      // New code for ustopo
      if (mapType === 'USTopo') {
        if (layer.values_.name === 'openstreet' || layer.values_.name === 'terrain'
          || layer.values_.name === 'toner' || layer.values_.name === 'bingsatellite'
          || layer.values_.name === 'bingstreet' || layer.values_.name === 'satellite' 
          || layer.values_.name === 'googlestreet'  || layer.values_.name === 'googlesatellite'
          || layer.values_.name === 'USGSTopo') {
          layer.setVisible(false);
        } else if (layer.values_.name === 'USTopo') {
          layer.setVisible(true);
        }
      }
    });
  }
  /* downloadPDF() {
    console.log('clicked on download PDF ');
    const dims = {
      a0: [1189, 841],
      a1: [841, 594],
      a2: [594, 420],
      a3: [420, 297],
      a4: [297, 210],
      a5: [210, 148],
    };
    const format = 'a4';
    const dim = dims[format];
    const resolution = 200; // 300dpi is very slow
    const width = Math.round((dim[0] * resolution) / 25.4);
    const height = Math.round((dim[1] * resolution) / 25.4);
    const map = this.basemapService.getCurrentBasemap();
    const size = map.getSize();
    const viewResolution = map.getView().getResolution();

    map.once('rendercomplete', function () {
      const mapCanvas = document.createElement('canvas');
      mapCanvas.width = width;
      mapCanvas.height = height;
      const mapContext = mapCanvas.getContext('2d');
      Array.prototype.forEach.call(
        document.querySelectorAll('.ol-layer canvas'),
        function (canvas) {
          if (canvas.width > 0) {
            const opacity = canvas.parentNode.style.opacity;
            mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
            const transform = canvas.style.transform;
            // Get the transform parameters from the style's transform matrix
            const matrix = transform
              .match(/^matrix\(([^\(]*)\)$/)[1]
              .split(',')
              .map(Number);
            // Apply the transform to the export map context
            CanvasRenderingContext2D.prototype.setTransform.apply(
              mapContext,
              matrix
            );
            mapContext.drawImage(canvas, 0, 0);
          }
        }
      );
      // const pdf = new jsPDF.jsPDF('landscape', undefined, format);
      const pdf = new jsPDF('landscape', undefined, format);
      pdf.addImage(
        mapCanvas.toDataURL('image/jpeg'),
        'JPEG',
        0,
        0,
        dim[0],
        dim[1]
      );
      pdf.save('FE-Map.pdf');
      // Reset original map size
      map.setSize(size);
      map.getView().setResolution(viewResolution);
    });
    // Set print size
    const printSize = [width, height];
    map.setSize(printSize);
    const scaling = Math.min(width / size[0], height / size[1]);
    map.getView().setResolution(viewResolution / scaling);
    console.log('download completed');
  } */
}
export let package_features_control= {
  "default":{
  "package": {
    "name": "play",
    "features": [
       {
        "name": "GeoTower",
        "isAccess": false
      },
      {
        "name": "Geobarsearch",
        "isAccess": false
      },
      {
        "name": "GeobarFMLocal",
        "isAccess": false
      },
      {
        "name": "GeobarFMCloud",
        "isAccess": false
      },
      {
        "name": "Geobarbase",
        "isAccess": false
      },
      {
        "name": "GeosolFramework",
        "isAccess": false
      },
      {
        "name": "GeosolFrameworkcoordinatesys",
        "isAccess": false
      },
      {
        "name": "GeosolConcepts",
        "isAccess": false
      },
      {
        "name": "GeosolObservations",
        "isAccess": false
      },
      {
        "name": "GeosolApplications",
        "isAccess": false
      },
      {
        "name": "GeosolAwarness",
        "isAccess": false
      },
      {
        "name": "GeosolGeoreferencing",
        "isAccess": false
      },
      {
        "name": "GeosolProduction",
        "isAccess": false
      },
      {
        "name": "GeosolManagement",
        "isAccess": false
      },
      {
        "name": "GeosolAnalysis",
        "isAccess": false
      },
      {
        "name": "showgeosolPresentation",
        // "name": "GeosolPresentation",
        "isAccess": false
      },
      {
        "name": "GeosolCompass",
        "isAccess": false
      },
      {
        "name": "GeotrayGeometrytool",
        "isAccess": false
      },
      {
        "name": "GeotrayAnnotatetool",
        "isAccess": false
      },
      {
        "name": "GeotrayGeopadtool",
        "isAccess": false
      },
      {
        "name": "GeotrayPropertiestool",
        "isAccess": false
      },
      {
        "name": "GeotrayAnalysis",
        "isAccess": false
      },
      {
        "name": "GeopadSharingmail",
        "isAccess": false
      },
      {
        "name": "GeopadSharingurl",
        "isAccess": false
      },
      {
        "name": "GeopadSharingdownload",
        "isAccess": false
      } 
    ]
  }
}


}