<div [ngClass]="{'fixed-alert': true, 'show': showAlert}">
    <div class="fixed-alert-container">
        <ngb-alert [type]="alertType" (close)="closeAlert()" [dismissible]="true">
            {{ alertMessage }}
        </ngb-alert>
    </div>
</div>
<div *ngIf="loadingSignupDiv">
    <div class="container-fluid d-flex flex-column justify-content-center align-items-center vh-100">
        <div class="position-absolute top-0 start-0 mt-3 ms-3 icon-link">
            <nav class="landingheader">
                <a href="https://mapsolgeo.com/contact-us/" target="_blank"><img src="../../assets/img/support_icon.svg"
                        alt="support" class="social-link"></a>
                <a href="https://mapsolgeo.com/videos/" target="_blank"><img src="../../assets/img/blog_icon.svg" alt="blog"
                        class="social-link"></a>
                <a href="https://mapsolgeo.com/tour/" target="_blank"><img src="../../assets/img/tour_icon.svg" alt="tour"
                        class="social-link"></a>
                <a href="https://mapsolgeo.com/tutorials/" target="_blank"><img src="../../assets/img/tutorials_icon.svg"
                        alt="tutorials" class="social-link"></a>
                <a href="https://mapsolgeo.com/videos/" target="_blank"><img src="../../assets/img/video_icon.svg"
                        alt="video" class="social-link"></a>
            </nav>
        </div>
        <div class="position-absolute top-0 mt-3 ms-3">
            <img src="../../assets/img/fe-logo.svg" alt="fuse.Earth Logo" class="logo-signup mb-2">
        </div>
        <div class="position-absolute top-0 end-0 mt-3 me-3">
            <p>Already an user? <a (click)="signupToLoginFun($event)"  class="btn btn-secondary text-white">Login</a></p>
        </div>
        <div class="container-fluid d-flex justify-content-center" >
            <div class="row w-60 d-flex justify-content-center align-items-center" *ngIf="signupDiv">
                <form [formGroup]="signupForm">
                    <div class="row mb-3">
                        <h6 class="text-start text-dark fw-bold">Sign Up</h6>
                        <div class="col-md-6 mb-3 mb-md-0">
                            <input formControlName="firstName" type="text" class="form-control" placeholder="First Name">
                            <span *ngIf="!signupForm.get('firstName').valid && signupForm.get('firstName').touched" class="error">
                                {{getErrorMsg(signupForm.get('firstName'), 'First Name')}}
                            </span>
                        </div>
                        <div class="col-md-6">
                            <input formControlName="lastName" type="text" class="form-control" placeholder="Last Name">
                            <span *ngIf="!signupForm.get('lastName').valid && signupForm.get('lastName').touched" class="error">
                                {{getErrorMsg(signupForm.get('lastName'), 'Last Name')}}
                            </span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6 mb-3 mb-md-0">
                            <input formControlName="email" type="email" class="form-control" placeholder="Email ID">
                            <span *ngIf="!signupForm.get('email').valid && signupForm.get('email').touched" class="error">
                                {{getErrorMsg(signupForm.get('email'), 'Email')}}
                            </span>
                        </div>
                        <div class="col-md-6 d-flex justify-content-start justify-content-md-end align-items-center">
                            <div class="btn-group w-100" role="group" aria-label="User type">
                                <!-- autocomplete="off" removed for input radio-->
                                <input type="radio" class="btn-check" name="userType" 
                                    checked [formControl]="signUpUserType" id="individualUser" value="individualUser" >
                                <label class="btn btn-secondary w-50" for="individualUser">Individual User</label>
                                
                                <input type="radio" class="btn-check" name="userType"
                                    [formControl]="signUpUserType" id="organizationUser" value="organizationUser">
                                <label class="btn btn-secondary w-50" for="organizationUser">Organization User</label>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3" *ngIf="(signUpUserType.value === 'organizationUser')">
                        <div class="col-md-12" (focusout)="onFocusOut($event)">
                            <input formControlName="organizationName" (keyup)="onSiteSearchChanged($event);"
                            (focus)="onFocusOrgName($event)" type="text" class="form-control" placeholder="Enter Organization Details">
                            <span *ngIf="!signupForm.get('organizationName').valid && signupForm.get('organizationName').touched" class="error">
                                {{getErrorMsg(signupForm.get('organizationName'), 'Organization Name')}}
                            </span>
                            <div class="org-search-list-container" *ngIf="displayTeamsList || showTeamList"
                                (mouseenter)="mouseInFunc($event)" (mouseleave)="mouseOutFunc($event)">
                                <p *ngIf="orgFetchStatus === fetchStatus.UNKNOWN">
                                    Type something to find organization(s).
                                </p>
                                <p *ngIf="orgFetchStatus === fetchStatus.INPROGRESS">
                                    Getting organization list...
                                </p>
                                <div [ngStyle]="{'display': orgFetchStatus === fetchStatus.COMPLETED ? 'inherit' : 'none'}"
                                    class="org-name-container" (click)="selectOrg(org)" *ngFor="let org of orgsList">
                                    <p class="org-name">{{org.name}}</p>
                                </div>
                                <p *ngIf="orgFetchStatus === fetchStatus.COMPLETED && orgsList.length === 0">
                                    No organization(s) found with the search string.
                                </p>
                            </div>
                        </div>
                    </div>    
                    <div class="row mb-3">
                        <div class="col-md-6 mb-3 mb-md-0">
                            <input formControlName="password" type="password" class="form-control" placeholder="Password">
                            <span *ngIf="!signupForm.get('password').valid && signupForm.get('password').touched" class="error">
                                {{getErrorMsg(signupForm.get('password'), 'Password')}}
                            </span>
                        </div>
                        <div class="col-md-6">
                        </div>
                    </div>            
                    <div class="row mb-3">
                        <p class="text-start text-dark m-0">Individual users can</p>
                        <div class="col-md-6 mb-3 mb-md-0">
                            <button type="button" class="btn btn-secondary w-100">Sign Up via Google</button>
                        </div>
                        <div class="col-md-6">
                            <button  (click)="signupEvent();" type="button" class="btn btn-dark w-100">Next</button>
                        </div>
                    </div>
                </form>
            </div>
            <!-- Payment Page Here -->
                <div class="row w-60 d-flex justify-content-center align-items-center" *ngIf="paymentDiv">
                <form [formGroup]="paymentPageForm">
                    <div class="row mb-3">
                        <h6 class="text-start text-dark fw-bold">Payment Details</h6>
                        <div class="col-md-6 mb-3 mb-md-0">
                            <input formControlName="cardNumber" type="text" class="form-control" placeholder="Card Number">
                            <span *ngIf="!paymentPageForm.get('cardNumber').valid && paymentPageForm.get('cardNumber').touched" class="error">
                                {{getErrorMsg(paymentPageForm.get('cardNumber'), 'Card Number')}}
                            </span>
                        </div>
                        <div class="col-md-3">
                            <input formControlName="expMonth" type="text" class="form-control" placeholder="Expiry Month">
                            <span *ngIf="!paymentPageForm.get('expMonth').valid && paymentPageForm.get('expMonth').touched" class="error">
                                {{getErrorMsg(paymentPageForm.get('expMonth'), 'Expiry Month')}}
                            </span>
                        </div>
                        <div class="col-md-3 mb-3 mb-md-0">
                            <input formControlName="expYear" type="text" class="form-control" placeholder="Expiry Year">
                            <span *ngIf="!paymentPageForm.get('expYear').valid && paymentPageForm.get('expYear').touched" class="error">
                                {{getErrorMsg(paymentPageForm.get('expYear'), 'Expiry Year')}}
                            </span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <input formControlName="cvv" type="text" class="form-control" placeholder="CVV">
                            <span *ngIf="!paymentPageForm.get('cvv').valid && paymentPageForm.get('cvv').touched" class="error">
                                {{getErrorMsg(paymentPageForm.get('cvv'), 'CVV')}}
                            </span>
                        </div>
                        <div class="col-md-6 mb-3 mb-md-0">
                            <input formControlName="cardHolderName" type="text" class="form-control" placeholder="Card Holder Name">
                            <span *ngIf="!paymentPageForm.get('cardHolderName').valid && paymentPageForm.get('cardHolderName').touched" class="error">
                                {{getErrorMsg(paymentPageForm.get('cardHolderName'), 'Card Holder Name')}}
                            </span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <button (click)="paymentDiv=false; signupDiv=true;" type="button" class="btn btn-dark w-100">Previous</button>
                        </div>
                        <div class="col-md-6">
                            <button (click)="chargeCreditCard()" type="button" class="btn btn-dark w-100">Submit</button>
                        </div>               
                    </div>     
                </form>
            </div>

            <!-- payment page end Here -->
        </div>
        <footer class="position-absolute bottom-0 start-0 m-3 d-flex justify-content-between">
            <p class="text-muted mb-0"><a href="https://mapsolgeo.com" target="_blank"><img
                        src="../../assets/img/mapsol-logo.svg" alt="fuse.Earth Logo" width="250" class="mb-2"></a></p>
            <div class="d-flex align-items-center">
                <p class="mb-0 me-2">Register a new Organization with Fuse.Earth</p>
                <button (click)="loadingSignupDiv=false;loadingOrgDiv=true;" type="button" class="btn btn-secondary">Create Organization</button>
            </div>
            <div class="icon-link">
                <nav>
                    <a href="https://www.facebook.com/fuse.earth" target="_blank"><img
                            src="../../assets/img/facebook_icon.svg" alt="Facebook" class="social-link"></a>
                    <a href="https://www.linkedin.com/showcase/fuse-earth/" target="_blank"><img
                            src="../../assets/img/linkedin_icon.svg" alt="LinkedIn" class="social-link"></a>
                    <a href="https://www.instagram.com/fuse_earth/?igshid=NTc4MTIwNjQ2YQ%3D%3D" target="_blank"><img
                            src="../../assets/img/instagram_icon.svg" alt="Instagram" class="social-link"></a>
                    <a href="https://x.com/fuse_earth" target="_blank"><img src="../../assets/img/twitter_icon.svg"
                            alt="Twitter" class="social-link"></a>
                </nav>
            </div>
        </footer>
    </div>
</div>
<div *ngIf="loadingOrgDiv">
    <app-landing-organization></app-landing-organization>
</div>